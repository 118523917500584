<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'falcondev',
  primary_color: '#0F0028',
  secondary_color: '#F67471',
  header: {
    background: {
      image: `/projekt/falcondev/elbworx-projekt-falconDev-header-3840.webp`,
    },
    project: 'falconDev',
    headline: 'Make It A<br> New Experience!',
    info_card: {
      description:
        'Logodesign und Entwicklung einer Brand Identity für ein Dresdner IT Start Up Unternehmen.',
      client: 'falconDev IT GmbH',
      roles: ['Logoentwicklung', 'Corporate Design'],
      project_duration: 'Juli 2021 bis September 2021',
      custom_button_class: 'bg-[#2441B1]',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3>Die Herausforderung</h3>

      <p>
        Wir entwickelten ein Logodesign für ein IT Start Up Unternehmen aus Dresden, welches
        Webapps, Websites sowie Apps für Desktop und Mobilgeräte entwickelt. Es setzt auf moderne
        Frameworks und ein agiles Vorgehen um einen zugänglichen, interaktiven Standard zu
        definieren. Wir gestalteten die Bildmarke analog zum Namen, wollten dabei aber den
        technischen Aspekt verdeutlichen, deshalb entschieden wir uns gegen alle grafisch
        detaillierten, organischen Ausarbeitungen. Klare Linien sollte die Zielstrebigkeit und
        Innovation des Teams hervor bringen.
      </p>
    </template>

    <Padding only="right">
      <Grid>
        <div class="col-span-5">
          <video
            :src="`${projectPath}elbworx-falcon-1080-logo.mp4`"
            :poster="`${projectPath}elbworx-projekt-falcondev-logo-1280.webp`"
            muted
            class="max-h-80 w-full object-cover object-center md:max-h-none"
            autoplay
            loop
            playsinline
          />
        </div>
        <div class="col-span-7">
          <Image
            :src="`${projectPath}elbworx-projekt-falcondev-book-1920.webp`"
            class="min-h-full object-cover"
          />
        </div>
      </Grid>
    </Padding>
    <Padding only="right">
      <Grid>
        <div class="col-span-8">
          <Image
            :src="`${projectPath}elbworx-projekt-falcondev-book_inside-1920.webp`"
            class="object-cover"
          />
        </div>
        <div class="col-span-full lg:col-span-4">
          <Text class="h-full justify-end px-8 2xl:justify-center">
            <h3>Farben</h3>
            <p>
              Die Farbwahl beruht auf dem Wunsch des Kunden, die Gestaltungswelt nicht zu dunkel
              wirken zu lassen. Deshalb entschieden wir uns die Farbpalette aus dem Verlauf eines
              Morgenhimmels aufzugreifen.
            </p>

            <h3>Webseite</h3>
            <p>
              Erste Gedanken zur Website beruhten auf der Gestaltung einer eigenen 3D Welt durch
              welche man sich virtuell bewegen kann um an Inhalten zu gelangen. Wir entwickelten ein
              Konzept, welches Symbolik, Animation und Navigation beinhaltete.
            </p>
          </Text>
        </div>
      </Grid>
    </Padding>
    <Padding>
      <Image
        :src="`${projectPath}elbworx-projekt-falcon-sticker1-960.webp`"
        class="mt-4 h-full w-full max-w-sm object-cover"
      />
    </Padding>

    <Tilt contain class="bg-primary">
      <Image
        class="mx-auto my-12 w-full max-w-4xl px-8"
        :src="`${projectPath}elbworx-projekt-falcondev-macbook-1920-erik.webp`"
      />
    </Tilt>

    <Grid>
      <Tilt class="col-span-5">
        <Image
          class="h-full w-full object-cover"
          :src="`${projectPath}elbworx-projekt-falcondev-businesscard-1920.webp`"
        />
      </Tilt>
      <div class="col-span-7 -mt-tilt sm:mr-12">
        <Image
          :src="`${projectPath}elbworx-projekt-falcon-sticker2-960.webp`"
          class="ml-auto w-full max-w-md object-contain lg:mb-72"
        />
      </div>
    </Grid>
  </Project>
</template>
